import React from 'react';
import config from '../../config';
import { Link } from 'gatsby';

export default function LanguagePickers() {
  return (
    <div id="language-picker">
      <ul className="languagepickers">
        <li key={'eu'}>
          <Link to="/" activeClassName="languageactive">
            <span className="label">EU</span>
          </Link>
          {/* <a className="active" href={"/"}>
                <span className="label">EU</span>
              </a> */}
        </li>
        <li key={'es'}>
          <Link to="/es/" activeClassName="languageactive">
            <span className="label">ES</span>
          </Link>
          {/* <a className="" href={"/es/"}>
                <span className="label">ES</span>
              </a> */}
        </li>
        <li key={'en'}>
          <Link to="/en/" activeClassName="languageactive">
            <span className="label">EN</span>
          </Link>
          {/* <a className="" href={"/en/"}>
                <span className="label">EN</span>
              </a> */}
        </li>
      </ul>
    </div>
  );
}
