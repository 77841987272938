import React from 'react';

import Layout from '../components/Layout';
// import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import headingimg from '../assets/images/headingimg.png';
// import galderapic from '../assets/images/logo.png';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
// import { Link } from 'gatsby';
import LanguagePickers from '../components/LanguagePickers';
import SortuHostaSection from '../components/SortuHostaSection';
import ErabileraInfoSection from '../components/ErabileraInfoSection';
import InfoaSection from '../components/InfoaSection'
// import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <header id="header">
      <div className="langpickerarea">
        <LanguagePickers />
      </div>
      <div className="content">
        <div className="image-header">
          <img src={headingimg} alt={config.heading} />
        </div>
        <div className="motto-header">
          <h1>
            EU
            {config.subHeading}{" "}
          </h1>
        </div>

        <ul className="actions">
          <li>
            <a
              href="#sortu-hosta"
              className="button primary icon fas fa-sign-in-alt"
            >
              Host berria sortu
            </a>
          </li>
          <li>
            <a href="#erabilera" className="button icon fa-question">
              Nola erabili
            </a>
          </li>
          <li>
            <a href="#infoa" className="button icon fa-info">
              Info
            </a>
          </li>
        </ul>
      </div>
      {/* <div className="image phone">
        <div className="inner">
          <img src={screen} alt="" />
        </div>
      </div> */}
    </header>

    <ErabileraInfoSection />

    

    <SortuHostaSection />

    <InfoaSection />

    <section id="supportsection" className="wrapper style3 special">
      <header className="major">
        <h2>Lagun gaitzazu zerbitzu hau martxan izan dezagun</h2>
        <p>
          Zerbitzu hau dohainekoa da erabilera oinarrizkoan. Ez duzu inolako
          ordainketarik egiteko beharrik, baina asko eskertuko genizuke,
          hobekuntzak egin, makinak ordaindu eta zerbitzu berriak aurrera
          ateratzen lagunkuko bait liguke. Ondoren moduak dituzu guri laguntza
          eman ahal izateko.
        </p>
      </header>
      <ul className="actions special">
        <li>
          <OutboundLink
            href="https://www.patreon.com/ddnseus"
            className="button primary icon fa-heart"
          >
            Patreon
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="https://www.patreon.com/ddnseus"
            className="button primary icon fa-coffee"
          >
            Buy Me a Coffee
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="mailto:info@ddns.eus"
            className="button primary icon fa-envelope"
          >
            Esaiguzu nola lagundu nahi duzun
          </OutboundLink>
        </li>
      </ul>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
