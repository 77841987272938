import React from 'react';
import { Link } from 'gatsby';



export default function LanguagePickers() {
  return (
    <section
      id="erabilera"
      className="wrapper style1 special halfheightwrappers"
    >
      <header className="major">
        <h2>Nola erabili dezakezu DDNS.eus?</h2>
      </header>
      <div className="inner alt">
        <p>
          Orokorrean bateragarria den router bat izatearekin nahikoa litzateke.
          Noip, dyndns edo google-en DNS dinamikoarekin bateragarriak den
          edozein router bateragarria da DDNS.eus ekin ere, protokolo bera
          jarraitzen bait dugu, Beharrezko datuak bete eta inolako aldaketarik
          egin beharrik gabe.
        </p>
      </div>
      <ul className="actions special">
        <li>
          <Link
            href="/#supportsection"
            className="button primary icon fa-heart"
          >
            Laguntza
          </Link>
          {/* <OutboundLink
            href="https://google.com"
            className="button primary icon fa-heart"
          >
            Laguntza
          </OutboundLink> */}
        </li>
        <li>
          <Link href="#infoa" className="button secondary icon fa-info">
            Informazioa
          </Link>
        </li>
      </ul>
    </section>
  );
}
