import React from 'react';
export default function SortuHostaSection() {
  return (
    <section id="sortu-hosta" className="wrapper special halfheightwrappers sortuhostasection">
      <header className="major">
        <h2>Zerbitzua erabili ahal izateko esaiguzu zein den zure emaila</h2>
        <span>
          ( <a href="#info">Zergatik behar da emaila <i className="icon fa-question"></i></a> )
        </span>
      </header>
      <div className="inner alt">
        <div className="row gtr-uniform">
          <div className="col-8 col-12-small formemailainputarea">
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Email Helbidea" aria-label="Email Helbidea" />
  
          </div>
            {/* <input
              class="btn btn-lg"
              name="email"
              id="email"
              type="email"
              placeholder="Email helbidea"
              required
            /> */}
          </div>
          <div className="col-4 col-12-small formbotoiaarea">
            <input type="submit" value="Bidali" className="primary"/>
          </div>
        </div>
        
      </div>
    </section>
  );
}
