import React from 'react';
import galderapic from '../assets/images/logo.png';



export default function LanguagePickers() {
  return (
    <section id="infoa" className="wrapper style2">
      <header className="major">
        <h2>Galdera ohikoenei erantzunak</h2>
      </header>

      <div className="inner alt">
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div id="zer-da-dns-dinamiko-bat" className="content">
            <h3>Zer da DNS dinamiko bat??</h3>
            <p>
              Morbi mattis ornare ornare. Duis quam turpis, gravida at leo
              elementum elit fusce accumsan dui libero, quis vehicula lectus
              ultricies eu. In convallis amet leo non sapien iaculis efficitur
              consequat lorem ipsum.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div className="content">
            <h3>Nola erabili dezaket zerbitzu hau?</h3>
            <p>
              Morbi mattis ornare ornare. Duis quam turpis, gravida at leo
              elementum elit fusce accumsan dui libero, quis vehicula lectus
              ultricies eu. In convallis amet leo non sapien iaculis efficitur
              consequat lorem ipsum.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div className="content">
            <h3>Nola sortu dezaket kontu bat?</h3>
            <p>
              This is a brief set of features we offer whilst deivering digital
              assets. The iost is growing thanks to the help and ideas of our
              patrons.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div className="content">
            <h3>Zergatik sartu behar dut nere emaila?</h3>
            <p>
              We host www.ddns.eus on Amazon AWS resources located in the
              European Union. All the data and communicationes are encrypted.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div className="content">
            <h3>Dohaniekoa al da zerbitzua?</h3>
            <p>
              We don't want to build our business model based on offering a free
              service that would lead us to sell "you" (your data, your
              behaviour, your whatever). But we still need incomes, so we've
              decided to offer our service in exchange to your support via{' '}
              <a href="https://www.patreon.com/ddnseus">our Patreon.com</a>.
              So,... no this is not a free service.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={galderapic} alt="" />
          </div>
          <div className="content">
            <h3>What will I get in exchange of becoming a patron?</h3>
            <p>
              In addition to helping us build a better independent digital asset
              distribution service, you'll be able to use the service and also
              decide which features will be developed in the near future.
            </p>
          </div>
        </section>
        {/* <section className="special">
          <ul className="icons labeled">
            <li>
              <span className="icon fa-camera-retro">
                <span className="label">Ipsum lorem accumsan</span>
              </span>
            </li>
            <li>
              <span className="icon fa-refresh">
                <span className="label">Sed vehicula elementum</span>
              </span>
            </li>
            <li>
              <span className="icon fa-cloud">
                <span className="label">Elit fusce consequat</span>
              </span>
            </li>
            <li>
              <span className="icon fa-code">
                <span className="label">Lorem nullam tempus</span>
              </span>
            </li>
            <li>
              <span className="icon fa-desktop">
                <span className="label">Adipiscing amet sapien</span>
              </span>
            </li>
          </ul>
        </section> */}
      </div>
    </section>
  );
}
